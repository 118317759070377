import Feature from 'helpers/feature';
import Breakpoints from 'helpers/breakpoints';
import { debouncedScroll } from 'helpers/bindScroll';

// START feature/PLOCTOPLUS-2043-intl-center-align-upsell-hero
import {
    intlCenterAlignHeroAbTest,
    setupPrototypesForIntlCenterAlignHeroAbTest,
} from 'aa/abTests/testClasses/IntlUpsellCenterAlignHeroes';
// END feature/PLOCTOPLUS-2043-intl-center-align-upsell-hero

// START TEST PLOCTOPLUS-2313: Center Content In CAMPAIGN Hero Upsell Bucket
import {
    centerContentInCampaignAbTest,
    setupPrototypesForCenterContentInCampaignAbTest,
} from 'aa/abTests/testClasses/CenterContentInCampaignHeroUpsellBucket';
// END TEST PLOCTOPLUS-2313: Center Content In CAMPAIGN Hero Upsell Bucket

//START CBSCOMSUB-8934 ProminentSignUpCTA
import {
    hasProminentCtaExperiment
} from 'aa/abTests/testClasses/ProminentSignUpCta';
//END CBSCOMSUB-8934 ProminentSignUpCTA

export default class AAUpsellHeroVideo {
    constructor() {
        this.breakpoints = new Breakpoints();

        // IDs for selectors and conditionals
        this.videoPortraitId = 'js-video-portrait';
        this.videoLandscapeId = 'js-video-landscape';
        this.videoTabletId = 'js-video-tablet';

        // vars
        this.currentVideoId = '';
        this.scrollLimit = 0;
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;

        // selectors
        this.videoPortrait = null;
        this.videoLandscape = null;
        this.hero = null;
        this.heroBackgroundDesktop = null;
        this.heroBackgroundMobile = null;

        this.listenerOptions = Feature.supportsPassive()
            ? {
                  capture: true,
                  passive: true,
              }
            : false;

        window.addEventListener('DOMContentLoaded', () => {
            this.init();
        });
    }

    init() {
        this.videoPortrait = document.querySelector('#' + this.videoPortraitId);
        this.videoLandscape = document.querySelector('#' + this.videoLandscapeId);
        this.videoTablet = document.querySelector('#' + this.videoTabletId);

        this.hero = document.querySelector('.hero');
        this.heroBackgroundDesktop = this.hero.getAttribute('data-background-desktop');
        this.heroBackgroundMobile = this.hero.getAttribute('data-background-mobile');

        if (this.videoPortrait || this.videoLandscape || this.videoTablet) {
            this.setupVideoEvents();
        } else {
            // START feature/PLOCTOPLUS-2043-intl-center-align-upsell-hero
            if (intlCenterAlignHeroAbTest()) {
                setupPrototypesForIntlCenterAlignHeroAbTest().bind(this)();
                // we return early because the a/b test will handle this.setupBackgroundEvents()
                return;
            }
            // END feature/PLOCTOPLUS-2043-intl-center-align-upsell-hero

            //START CBSCOMSUB-8934 ProminentSignUpCTA
            if(hasProminentCtaExperiment()){
                return;
            }
            //END CBSCOMSUB-8934 ProminentSignUpCTA

            this.setupBackgroundEvents();
        }

        // START TEST PLOCTOPLUS-2313: Center Content In CAMPAIGN Hero Upsell Bucket
        // this is down here because this test will utilize videos and images depending on breakpoint
        if (centerContentInCampaignAbTest()) {
            setupPrototypesForCenterContentInCampaignAbTest().bind(this)();
        }
        // END TEST PLOCTOPLUS-2313: Center Content In CAMPAIGN Hero Upsell Bucket
    }

    setupVideoEvents() {
        this.setupVideoPositionValue();
        this.setupPlayPause();
        this.setupVideoPortraitListeners();
        this.setupVideoLandscapeListeners();
        this.setupVideoTabletListeners();
    }

    // stop position fixed when outside of hero viewport
    setupVideoPositionValue() {
        debouncedScroll(() => this.videoPosition());
    }

    videoPosition() {
        let heroHeight = document.querySelector('.hero').clientHeight;

        if (Number.isInteger(heroHeight)) {
            if (document.documentElement.scrollTop > heroHeight) {
                this.videoPortrait.classList.toggle('fixed', false);
                this.videoLandscape.classList.toggle('fixed', false);
                this.videoTablet.classList.toggle('fixed', false);
            } else {
                this.videoPortrait.classList.toggle('fixed', true);
                this.videoLandscape.classList.toggle('fixed', true);
                this.videoTablet.classList.toggle('fixed', true);
            }
        }
    }

    setupBackgroundEvents() {
        window.addEventListener(
            'resize',
            () => {
                this.addHeroBackgroundImage();
            },
            this.listenerOptions,
        );

        // show background image
        this.addHeroBackgroundImage();
    }

    setupPlayPause() {
        // we include a polyfil for IE so it should be universally supported
        if ('IntersectionObserver' in window) {
            let observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    let windowHeight = window.innerHeight;
                    let windowWidth = window.innerWidth;

                    let video;
                    if (windowHeight > windowWidth) {
                        video = this.videoPortrait;
                    } else {
                        video = this.videoLandscape;
                    }

                    if (video.src) {
                        if (entry.isIntersecting) {
                            if (video.paused) {
                                video
                                    .play()
                                    .then(() => {})
                                    .catch((e) => {});
                            }
                        } else {
                            if (!video.paused) {
                                video.pause();
                            }
                        }
                    }
                });
            });

            observer.observe(this.hero);
        }
    }

    setupVideoPortraitListeners() {
        if (this.videoPortrait) {
            this.videoPortrait.addEventListener(
                'stalled',
                () => {
                    this.setupBackgroundEvents();
                },
                this.listenerOptions,
            );

            this.videoPortrait.addEventListener(
                'error',
                () => {
                    this.setupBackgroundEvents();
                },
                this.listenerOptions,
            );
        }
    }

    setupVideoTabletListeners() {
        if (this.videoTablet) {
            this.videoTablet.addEventListener(
                'stalled',
                () => {
                    this.setupBackgroundEvents();
                },
                this.listenerOptions,
            );

            this.videoTablet.addEventListener(
                'error',
                () => {
                    this.setupBackgroundEvents();
                },
                this.listenerOptions,
            );
        }
    }

    setupVideoLandscapeListeners() {
        if (this.videoLandscape) {
            this.videoLandscape.addEventListener(
                'stalled',
                () => {
                    this.setupBackgroundEvents();
                },
                this.listenerOptions,
            );

            this.videoLandscape.addEventListener(
                'error',
                () => {
                    this.setupBackgroundEvents();
                },
                this.listenerOptions,
            );
        }
    }

    addHeroBackgroundImage() {
        let mobileMax = this.breakpoints.getBreakpoint('tabletPortrait').max;
        let currentMax = this.breakpoints.getBreakpoint(
            this.breakpoints.getCurrentBreakpointName(),
        ).max;
        if (currentMax > mobileMax) {
            this.__setBackgroundImage(this.heroBackgroundDesktop);
        } else {
            this.__setBackgroundImage(this.heroBackgroundMobile);
        }
    }

    __setBackgroundImage(img) {
        this.hero.style.backgroundImage = "url('" + img + "')";
    }
}
