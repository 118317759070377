<template>
    <div class="success-create" aa-region="live tv">
        <div class="center-column">
            <span class="large-copy">{{ headline }}</span>
            <span class="medium-copy">{{ subheadline }}</span>
            <div class="logo" v-if="!isPartnerSubscription">
                <img :src="provider.logoUrl" :alt="altLogo(provider.name)" />
            </div>
            <div class="instructional-copy">
                <p v-if="!isPartnerSubscription">
                    {{ $getLocale('to_enjoy_brand_you_must', { brand: 'Paramount+' }) }}
                </p>
                <p>{{ $getLocale('theres_just_one_more_step') }}</p>
            </div>
            <ButtonCta :buttonConfig="createAccountConfig" />
            <div class="alternative-flow">
                <span class="deci">{{ $getLocale('already_have_an_account') }}</span>
                <ButtonCta :buttonConfig="bindAccountConfig" />
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonCta from 'atoms/ButtonCta';
    import { ACTION_NAMES, tracking } from 'services/Tracking';
    import { mapGetters, mapState } from 'vuex';
    import {
        ACTION_NAMES as AA_ACTION_NAMES,
        PAGE_TYPES,
    } from 'aa/vue/constants/aaTrackingConstants';
    import { FLOW_TYPE } from 'models/FlowModel';

    export default {
        name: 'SuccessConfirmation',
        components: {
            ButtonCta,
        },
        props: {
            provider: {
                type: Object,
            },
            createAccountCallback: {
                type: Function,
            },
            bindAccountCallback: {
                type: Function,
            },
            isPartnerSubscription: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState('flow', {
                flow: (state) => state.config,
            }),
            ...mapState('authSuite', {
                selectedProviderName: (state) => state.selectedProviderName,
            }),
            ...mapGetters({
                ottActivationPartner: 'flow/ottActivationPartner',
            }),
            altLogo: () => (providerName) => {
                return `logo ${providerName}`;
            },
            createAccountConfig() {
                return {
                    styleClass: ['button', 'primary'],
                    displayText: this.$getLocale('create_an_account'),
                    event: () => {
                        this.handleCreateAccountTracking();
                        this.createAccountCallback(this);
                    },
                };
            },
            bindAccountConfig() {
                return {
                    styleClass: ['button', 'secondary'],
                    displayText: this.$getLocale('sign_in'),
                    event: () => {
                        this.handleBindAccountTracking();
                        this.bindAccountCallback(this);
                    },
                };
            },
            partnerName() {
                return this.$parent.partner.partnerAuthAttributes.csp_display_name;
            },
            headline() {
                return this.isPartnerSubscription
                    ? this.$getLocale('partner_subscription_headline')
                    : this.$getLocale('youre_in');
            },
            subheadline() {
                return this.isPartnerSubscription
                    ? this.$getLocale('partner_subscription_subheadline', {
                          partner: this.partnerName,
                      })
                    : this.$getLocale('currently_connected');
            },
        },
        methods: {
            mvpdTrackingParams(ctaText = '') {
                return {
                    pageType: PAGE_TYPES.RENDEZVOUS_SIGNIN_COMPLETE,
                    ctaText: ctaText,
                    activationShortCode: this.flow.code,
                    ottActivationPartner: this.flow?.activationPartner,
                    authPartnerId: this.selectedProviderName,
                };
            },
            handleCreateAccountTracking() {
                let trackAction = ACTION_NAMES.CONFIRMATION_SIGNUP;
                let trackingParams = {
                    clickLabel: this.$getLocale('create_an_account'),
                };
                if (this.flow.type === FLOW_TYPE.AGNOSTIC_RENDEZVOUS) {
                    trackAction = AA_ACTION_NAMES.MVPD_CREATE_ACCOUNT_CTA;
                    trackingParams = this.mvpdTrackingParams(this.$getLocale('create_an_account'));
                }
                tracking.trackAction(trackAction, trackingParams);
            },
            handleBindAccountTracking() {
                let trackAction = ACTION_NAMES.CONFIRMATION_SIGNUP;

                let trackingParams = {
                    clickLabel: this.$getLocale('already_have_an_account'),
                };

                if (this.flow.type === FLOW_TYPE.AGNOSTIC_RENDEZVOUS) {
                    trackAction = AA_ACTION_NAMES.MVPD_LOGIN_CTA;

                    trackingParams = this.mvpdTrackingParams(this.$getLocale('sign_in'));
                }

                tracking.trackAction(trackAction, trackingParams);
            },
        },
    };
</script>
