import { tracking } from 'services/Tracking';
import { getLocale } from 'services/LocalizationService';
import Domain from 'helpers/Domain';
import { labeledInputConfig } from 'vue/FormConfig';

/* jshint esversion: 6 */

const windowsClass = navigator.userAgent.indexOf('Win') != -1 ? 'buttonWindows' : '';

/**
 * @param key { string }
 *
 * @return { object }
 */
function hiddenInputConfig(key, overrideConfig) {
    let defaultConfig = {
        type: 'hidden',
        name: key,
        value: '',
        ref: key,
        validation: [],
    };

    if (overrideConfig && typeof overrideConfig === 'object') {
        return Object.assign(defaultConfig, overrideConfig);
    } else {
        return defaultConfig;
    }
}

function selectOption(value, text) {
    if (!text) {
        text = value;
    }

    return {
        text: text,
        value: value,
    };
}

export const SignInConfig = {
    inputList: {
        email: labeledInputConfig('email', {
            type: 'email',
            label: getLocale('email', {}),
            tabIndex: 0,
            maxlength: 320,
            styleClass: ['qt-emailtxtfield'],
            ariaDescribedBy: 'email_error_msg',
        }),
        password: labeledInputConfig('password', {
            type: 'password',
            label: getLocale('password', {}),
            tabIndex: 0,
            maxlength: 30,
            styleClass: ['qt-passwordtxtfield', 'password-field'],
            ariaDescribedBy: 'password_error_msg',
            autoComplete: 'off',
        }),
    },
    hiddenInputList: {
        tk_trp: hiddenInputConfig('tk_trp'),
    },
    buttonConfig: {
        displayText: getLocale('_continue', {}),
        event: function (e) {
            console.log(e);
        },
        tabIndex: 0,
        styleClass: ['button', 'qt-continuebtn', 'vue-focusable', windowsClass],
        aaLink: tracking.aaLinkFilter(['signin', '', 'continue']),
    },
    aaRegion: 'signin',
};

export const NonNativeSignUpCtaConfig = {
    buttonConfig: {
        displayText: 'Sign Up',
        event: function (e) {
            console.log(e);
        },
        tabIndex: 0,
        styleClass: ['button', 'secondary', 'non-native-signup-btn', 'vue-focusable'],
    },
};

export const SignUpConfig = {
    // @deprecated: inputList, select, checkbox
    // check SignUp.phpinputList: {
    fullName: labeledInputConfig('fullName', {
        label: getLocale('full_name', {}),
        tabIndex: 1,
        maxlength: 400,
        styleClass: ['qt-nametxtfield'],
    }),
    email: labeledInputConfig('email', {
        label: getLocale('email', {}),
        tabIndex: 2,
        maxlength: 320,
        styleClass: ['qt-emailtxtfield'],
    }),
    password: labeledInputConfig('password', {
        label: getLocale('password', {}),
        type: 'password',
        tabIndex: 3,
        maxlength: 30,
        iconClass: 'icon--info',
        tooltip: {
            id: 'info-cvv',
            content: `<p>${getLocale('create_a_password', {})}</p>`,
        },
        styleClass: ['qt-pwdtxtfield', 'password-field'],
        autoComplete: 'off',
    }),
    zip: labeledInputConfig('zip', {
        label: getLocale('postal_code', {}),
        tabIndex: 4,
        maxlength: 5,
        styleClass: ['qt-ziptxtfield'],
    }),
    birthday: labeledInputConfig('birthday', {
        type: 'birthday',
        label: getLocale('birth_date', {}),
        tabIndex: 5,
        class: 'form-control',
        name: 'birthday',
        styleClass: ['qt-bdaytxtfield'],
        maxlength: 10,
    }),
    select: {
        id: 'gender',
        name: 'gender',
        wrapperId: 'custom-selector-wrapper',
        label: getLocale('gender', {}),
        selected: '',
        ref: 'gender',
        tabIndex: 6,
        styleClass: ['qt-gendertxtfield'],
        options: [
            {
                text: '',
                value: '',
                default: true,
                hidden: true,
            },
            {
                text: getLocale('male', {}),
                value: getLocale('male', {}),
            },
            {
                text: getLocale('female', {}),
                value: getLocale('female', {}),
            },
            {
                text: getLocale('other', {}),
                value: getLocale('other', {}),
            },
            {
                text: getLocale('prefer_not_to_say', {}),
                value: getLocale('prefer_not_to_say', {}),
            },
        ],
    },
    checkbox: {
        styleClass: ['cbs-checkbox-input', 'qt-agreecheck'],
        elementId: '_agree',
        name: '_agree',
        wrapperClass: 'cbs-checkbox cbs-checkbox--legal left',
        checked: false,
        tabIndex: 0,
        inputLabel:
            `By registering you become a member of the CBS Interactive family of sites and you have read and
            agree to the
            <a href="https://cbsi.secure.force.com/CBSi/articles/FAQ/CBSi-Terms-of-Use?template=template_tougl&amp;` +
            `referer=tougl.com&amp;data=&amp;cfs=default" target="_blank" class="ui-link qt-terms">Terms of Use</a>,
            <a href="https://cbsi.secure.force.com/CBSi/articles/FAQ/Privacy-Policy?` +
            `categories=CBS_Interactive%3AmPrivacy&amp;template=template_mobilePrivacy&amp;referer=mobileprivacy.com&amp;` +
            `data=&amp;cfs=default" target="_blank" class="ui-link qt-policy">Privacy Policy</a> and
            <a href="https://cbsi.secure.force.com/CBSi/articles/FAQ/mThird-Party-Social-Networking-Services?` +
            `template=template_mobilepp&amp;referer=mobilepp.com&amp;data=&amp;cfs=default" target="_blank"
            class="ui-link qt-videopolicy">Video Services Policy</a>.
            You agree to receive updates, alerts and promotions from CBS and that CBS may share information about you
            with our marketing partners so that they may contact you by email or otherwise about their products or services.`,
    },
    hiddenInputList: {
        tk_trp: hiddenInputConfig('tk_trp'),
    },
    buttonConfig: {
        type: 'button',
        displayTexts: {
            ['Loading']: getLocale('_processing', {}),
            ['Enabled']: Domain.isDomestic()
                ? getLocale('continue', {})
                : getLocale('agree_and_continue', {}),
        },
        id: 'payment-submit',
        displayText: Domain.isDomestic()
            ? getLocale('continue', {})
            : getLocale('agree_and_continue', {}),
        event: function (e) {
            console.log(e);
        },
        tabIndex: 0,
        styleClass: ['button', 'qt-continuebtn', 'vue-focusable', windowsClass],
        aaLink: tracking.aaLinkFilter(['signup', '', 'continue']),
    },
    aaRegion: 'signup',
};

export const CreditCardPaymentConfig = {
    recurlyFieldConfig: {
        ccNumber: {
            type: 'recurly-hosted',
            name: 'ccNumber',
            recurlyId: 'cc-number',
            label: getLocale('credit_card_number'),
            dataRecurly: 'number',
            styleClass: ['qt-cctxtfield'],
            validation: ['required', 'recurly_hosted'],
        },

        expireMonth: {
            type: 'recurly-hosted',
            name: 'expireMonth',
            recurlyId: 'cc-expire-month',
            placeholderMsg: 'MM',
            label: getLocale('exp_mm'),
            validationLabel: getLocale('month'),
            dataRecurly: 'month',
            validation: ['required', 'recurly_hosted'],
        },

        expireYear: {
            type: 'recurly-hosted',
            name: 'expireYear',
            recurlyId: 'cc-expire-year',
            placeholderMsg: 'YY',
            label: getLocale('exp_yy'),
            validationLabel: getLocale('year'),
            dataRecurly: 'year',
            validation: ['required', 'recurly_hosted'],
        },

        cvv: {
            type: 'recurly-hosted',
            name: 'cvv',
            recurlyId: 'cc-cvv',
            label: getLocale('cvv'),
            dataRecurly: 'cvv',
            styleClass: ['qt-cvvtxtfield'],
            iconClass: 'icon--info',
            tooltipID: 'info-cvv',
            validation: ['required', 'recurly_hosted'],
        },
    },
    // TODO: Delete after Shared Module Release to prod
    hiddenInputList: {
        country: hiddenInputConfig('country', {
            value: 'US',
            dataRecurly: 'country',
        }),
        currency: hiddenInputConfig('currency', {
            value: 'USD',
            dataRecurly: 'currency',
        }),
    },
    recurlyConfig: {
        publicKey: null,
        cors: true,
        required: ['cvv'],
        fields: {
            number: {
                selector: '#cc-number',
                tabIndex: '0',
                style: {
                    placeholder: {
                        content: getLocale('credit_card_number'),
                    },
                },
            },
            month: {
                placeholder: 'month',
                selector: '#cc-expire-month',
                tabIndex: '0',
                style: {
                    placeholder: {
                        content: getLocale('exp_mm'),
                    },
                },
            },
            year: {
                selector: '#cc-expire-year',
                tabIndex: '0',
                style: {
                    placeholder: {
                        content: getLocale('exp_yy'),
                    },
                },
            },
            cvv: {
                selector: '#cc-cvv',
                tabIndex: '0',
                style: {
                    placeholder: {
                        content: getLocale('cvv'),
                    },
                },
            },
        },
        style: {
            all: {
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontWeight: 'normal',
                lineHeight: '22px',
                fontColor: '#1D1D1D',
                fontSmoothing: 'antialiased',
                placeholder: {
                    color: '#ffffff',
                },
            },
        },
    },

    aaRegion: 'payment',
};

export const OrderSummaryConfig = {
    inputList: {
        couponCode: {
            id: 'coupon-code',
            type: 'text',
            ref: 'couponCode',
            placeholderMsg: getLocale('enter_coupon_code', {}),
            value: '',
            styleClass: ['qt-coupontxtfield'],
        },
    },

    hiddenInputList: {
        couponRecurly: hiddenInputConfig('couponRecurly', {
            id: 'coupon-recurly',
            dataRecurly: 'coupon',
            value: '',
        }),
        plan: hiddenInputConfig('plan', {
            dataRecurly: 'plan',
        }),
    },

    buttonConfig: {
        styleClass: ['button', 'qt-couponbtn', 'secondary'],
        tabIndex: 0,
    },
};

export const ForgotPassConfig = {
    inputList: {
        email: labeledInputConfig('email', {
            type: 'email',
            ref: 'email',
            label: getLocale('email', {}),
            tabIndex: 0,
            maxlength: 320,
            styleClass: ['qt-emailtxtfield'],
        }),
    },
    hiddenInputList: {
        tk_trp: hiddenInputConfig('tk_trp'),
    },
    buttonConfig: {
        displayText: getLocale('send_email', {}),
        event: function (e) {
            console.log(e);
        },
        tabIndex: 0,
        styleClass: ['button', 'qt-continuebtn', 'disabled', windowsClass],
    },
};

export const ResetPassConfig = {
    inputList: {
        password: labeledInputConfig('password', {
            type: 'password',
            label: getLocale('password', {}),
            tabIndex: 0,
            maxlength: 30,
            styleClass: ['qt-pwdtxtfield', 'password-field'],
            autoComplete: 'off',
        }),
        passwordConfirm: labeledInputConfig('passwordConfirm', {
            type: 'password',
            label: getLocale('password_confirm', {}),
            tabIndex: 0,
            maxlength: 30,
            styleClass: ['qt-pwdconfirmtxtfield', 'password-field'],
            autoComplete: 'off',
        }),
    },
    hiddenInputList: {
        tk_trp: hiddenInputConfig('tk_trp'),
    },
    buttonConfig: {
        displayText: getLocale('reset_password', {}),
        event: function (e) {
            console.log(e);
        },
        tabIndex: 0,
        styleClass: ['button', 'qt-continuebtn', windowsClass],
    },
};

export const PickAPlanConfig = {
    checkbox: {
        // hard code this
        styleClass: 'cbs-checkbox-input',
        elementId: 'annual-plan',
        wrapperClass: 'cbs-checkbox flex-center qt-annualbox',
        checked: false,
        inputLabel: '',
        tabIndex: 0,
    },
    button: {
        // hard code
        styleClass: `button qt-continuebtn ${windowsClass}`,
        href: 'javascript:void(0)',
        tabIndex: 0,
        aaLink: 'pick a plan||continue|||||||',

        // will be overwritten in component
        event: function () {},
        displayText: '',
    },
};

export const UpsellPlansCTAConfig = {
    button: {
        // hard code
        styleClass: `button focusable button__extra-large ${windowsClass}`,
        href: 'javascript:void(0)',
        tabIndex: 0,
        aaLink: 'pick a plan||continue|||||||',

        // will be overwritten in component
        event: function () {},
        displayText: '',
    },
};

export const GiftCardConfig = {
    inputList: {
        code: {
            type: 'text',
            styleClass: '',
            elementId: 'code',
            wrapperClass: '',
            placeholderMsg: '',
            value: '',
            ref: 'code',
            label: 'PIN',
        },
    },
    hiddenInputList: {
        tk_trp: hiddenInputConfig('tk_trp'),
    },
    buttonConfig: {
        displayText: 'Redeem',
        styleClass: ['button', 'button_primary'],
        href: 'javascript:void(0)',
    },
    thirdPartyButtonBackConfig: {
        displayText: 'Go Back',
        styleClass: ['button', 'secondary'],
        href: 'javascript:void(0)',
    },
    thirdPartyButtonNextConfig: {
        displayText: 'Learn More',
        styleClass: ['button'],
        href: 'javascript:void(0)',
    },
};

export const ShowtimeBundleConfig = {
    buttonConfig: {
        styleClass: ['button', 'secondary'],
        href: 'javascript:void(0)',
        tabIndex: 0,
        aaLink: '',

        event: function () {},

        displayText: '',
    },
};
export const PaymentConfig = {
    inputList: {
        firstName: labeledInputConfig('firstName', {
            id: 'first-name',
            label: 'First Name',
            dataRecurly: 'first_name',
            maxlength: 200,
            tabIndex: 1,
            styleClass: ['qt-firstname'],
            validation: ['required'],
        }),
        lastName: labeledInputConfig('lastName', {
            id: 'last-name',
            label: 'Last Name',
            dataRecurly: 'last_name',
            maxlength: 200,
            tabIndex: 2,
            styleClass: ['qt-lastname'],
            validation: ['required'],
        }),
        address: labeledInputConfig('address', {
            id: 'address',
            label: 'Address',
            dataRecurly: 'address1',
            tabIndex: 3,
            styleClass: ['qt-addresstxtfield'],
            validation: ['required'],
        }),
        city: labeledInputConfig('city', {
            id: 'city',
            label: 'City',
            dataRecurly: 'city',
            tabIndex: 4,
            styleClass: ['qt-citytxtfield'],
            validation: ['required'],
        }),
        state: labeledInputConfig('state', {
            type: 'select',
            id: 'state',
            label: 'State',
            wrapperId: 'custom-selector-wrapper',
            selected: '',
            ref: 'state',
            dataRecurly: 'state',
            options: [
                selectOption('AK'),
                selectOption('AL'),
                selectOption('AR'),
                selectOption('AZ'),
                selectOption('CA'),
                selectOption('CO'),
                selectOption('CT'),
                selectOption('DC'),
                selectOption('DE'),
                selectOption('FL'),
                selectOption('GA'),
                selectOption('HI'),
                selectOption('IA'),
                selectOption('ID'),
                selectOption('IL'),
                selectOption('IN'),
                selectOption('KS'),
                selectOption('KY'),
                selectOption('LA'),
                selectOption('MA'),
                selectOption('MD'),
                selectOption('ME'),
                selectOption('MI'),
                selectOption('MN'),
                selectOption('MO'),
                selectOption('MS'),
                selectOption('MT'),
                selectOption('NC'),
                selectOption('ND'),
                selectOption('NE'),
                selectOption('NH'),
                selectOption('NJ'),
                selectOption('NM'),
                selectOption('NV'),
                selectOption('NY'),
                selectOption('OH'),
                selectOption('OK'),
                selectOption('OR'),
                selectOption('PA'),
                selectOption('RI'),
                selectOption('SC'),
                selectOption('SD'),
                selectOption('TN'),
                selectOption('TX'),
                selectOption('UT'),
                selectOption('VA'),
                selectOption('VI'),
                selectOption('VT'),
                selectOption('WA'),
                selectOption('WI'),
                selectOption('WV'),
                selectOption('WY'),
            ],
            tabIndex: 5,
            styleClass: ['qt-statetxtfield'],
            validation: ['required'],
        }),

        zip: labeledInputConfig('zip', {
            id: 'zip',
            label: 'ZIP Code',
            dataRecurly: 'postal_code',
            maxlength: 16,
            tabIndex: 6,
            styleClass: ['qt-ziptxtfield'],
            validation: ['required'],
        }),
    },

    recurlyFieldConfig: {
        ccNumber: {
            type: 'recurly-hosted',
            name: 'ccNumber',
            recurlyId: 'cc-number',
            label: 'Credit Card Number',
            dataRecurly: 'number',
            styleClass: ['qt-cctxtfield'],
            validation: ['required', 'recurly_hosted'],
        },

        expireMonth: {
            type: 'recurly-hosted',
            name: 'expireMonth',
            recurlyId: 'cc-expire-month',
            placeholderMsg: 'MM',
            label: 'Exp. MM',
            dataRecurly: 'month',
            validation: ['required', 'recurly_hosted'],
        },

        expireYear: {
            type: 'recurly-hosted',
            name: 'expireYear',
            recurlyId: 'cc-expire-year',
            placeholderMsg: 'YY',
            label: 'Exp. YY',
            dataRecurly: 'year',
            validation: ['required', 'recurly_hosted'],
        },

        cvv: {
            type: 'recurly-hosted',
            name: 'cvv',
            recurlyId: 'cc-cvv',
            label: 'CVV',
            dataRecurly: 'cvv',
            styleClass: ['qt-cvvtxtfield'],
            iconClass: 'icon--info',
            tooltipID: 'info-cvv',
            validation: ['required', 'recurly_hosted'],
        },
    },

    hiddenInputList: {
        country: hiddenInputConfig('country', {
            value: 'US',
            dataRecurly: 'country',
        }),
        currency: hiddenInputConfig('currency', {
            value: 'USD',
            dataRecurly: 'currency',
        }),
    },

    recurlyConfig: {
        publicKey: null,
        cors: true,
        required: ['cvv'],
        fields: {
            number: {
                selector: '#cc-number',
                tabIndex: '7',
            },
            month: {
                selector: '#cc-expire-month',
                tabIndex: '8',
            },
            year: {
                selector: '#cc-expire-year',
                tabIndex: '9',
            },
            cvv: {
                selector: '#cc-cvv',
                tabIndex: '10',
            },
        },
        style: {
            all: {
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontWeight: 'normal',
                lineHeight: '22px',
                fontColor: '#1D1D1D',
                fontSmoothing: 'antialiased',
            },
        },
    },

    aaRegion: 'payment',
};

export const PartnerConfig = {
    continueButtonConfig: {
        styleClass: ['button', 'primary'],
    },
    signOutButtonConfig: {
        styleClass: ['button', 'secondary'],
    },
};
