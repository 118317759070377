import Feature from 'helpers/feature';
import { debouncedScroll } from 'helpers/bindScroll';
import { trackAction } from 'helpers/trackingOM';
import UrlHelper from 'helpers/UrlHelper';
import Domain from 'helpers/Domain';
import Product from 'aa/vue/models/Product';
// TODO: fix it so that all scroll events pass through the om check

export default class AAUpsellTracking {
    constructor() {
        // get all headings
        this.elements = [];
        this.clickElements = document.querySelectorAll('h1, h3');

        // get all ctas
        this.upsellButtons = document.querySelectorAll('.aa-primary-upsell .hero__cta .button');

        // count the sections
        this.rowHeaderNumberTotal = 0;

        // check if mobile cta is present
        this.isMobileCtaPresent = document.getElementsByClassName('js-cta-mobile-tracking');

        // check if showtime bundle div is present
        this.getShowtimeBundleBannerClass =
            document.getElementsByClassName('showtime-bundle-wrapper');

        // check if partner bundle class in div is present
        this.getPartnerBundleBannerClass =
            document.getElementsByClassName('partner-bundle-wrapper');

        // used to prevent duplicate tracking calls per page view
        this.trackingStatus = [];

        this.listenerOptions = Feature.supportsPassive()
            ? {
                  capture: true,
                  passive: true,
              }
            : false;

        window.addEventListener('DOMContentLoaded', () => {
            this.init();
        });
    }

    init() {
        this.setConstructorProps();
        this.setupMutationObserver();
        this.setupTrackingStatus();
        this.setupScrollHandler();
        this.setupRedeemTracking();
        this.setupStudentTracking();
        this.setupTrackingForCTAButtons();
        this.setupUpsellCtaTracking();
        this.setupMobileTracking();
        this.showtimeBundleCtaTracking();
        this.partnerBundleCtaTracking();
        this.setupProviderLinkTracking();
        // START CBSCOMSUB-6356: Free Content Hub
        this.freeContentHubCTATracking();
        // END CBSCOMSUB-6356: Free Content Hub

        if (Domain.isInternational()) {
            this.setupUpsellSignInTracking();
        }

        // START TEST PLGRWEB-366 : Test Showtime Upsell Screen Subcopy Link to Learn More
        this.showtimeUpsellScreenSubcopyTracking();
        // END TEST PLGRWEB-366 : Test Showtime Upsell Screen Subcopy Link to Learn More

        this.wait();
    }

    wait() {
        document.addEventListener('DOMContentLoaded', () => {
            // fire tracking if something is in view
            this.constructor.onOmLoad(this.handleScroll.bind(this));
        });
    }

    setupMutationObserver() {
        // Select the node that will be observed for mutations
        const targetNode = document.getElementById('main-container');

        // Options for the observer (which mutations to observe)
        const config = { attributes: false, childList: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback = (mutationList, observer) => {
            let callConstructorProps = false;

            for (const mutation of mutationList) {
                // check if the mutation is in a section then flag to call the constructor props
                if (mutation.target.matches('section')) {
                    callConstructorProps = true;
                    break;
                }
            }

            if (callConstructorProps) {
                this.setConstructorProps();
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }

    setElements() {
        this.elements = document.querySelectorAll(
            'h1.includeInScrollTracking, h3.includeInScrollTracking',
        );
    }

    setRowHeaderNumberTotals() {
        this.rowHeaderNumberTotal = getRowHeaderNumberTotal();
    }

    setConstructorProps() {
        this.setElements();
        this.setRowHeaderNumberTotals();
        this.updateTrackingIndex();
    }

    updateTrackingIndex() {
        let updatedTrackingStatus = [];
        this.trackingStatus.forEach((value, idx) => {
            // hero is tracked, so skip zero index, and anything beyond one
            if (idx === 1) {
                updatedTrackingStatus.push(false);
            } else {
                updatedTrackingStatus.push(value);
            }
        });
        this.trackingStatus = updatedTrackingStatus;
    }

    setupTrackingStatus() {
        this.elements.forEach((el, idx) => {
            this.trackingStatus.push(true);
        });
    }

    setupScrollHandler() {
        debouncedScroll(
            () => this.constructor.onOmLoad(this.handleScroll.bind(this)),
            this.listenerOptions,
        );
    }

    setupTrackingForCTAButtons() {
        this.upsellButtons.forEach((el, idx) =>
            el.addEventListener('click', (e) => {
                this.handleCTAButtonClick(idx);
            }),
        );
    }

    setupRedeemTracking() {
        let redeemLink = this.getFaqLink('gift-card');

        if (!redeemLink) return;

        redeemLink.addEventListener('click', function (e) {
            e.preventDefault();

            try {
                om.trackAction('trackGetOffer', {
                    pageTitle: document.title,
                    ctaText: redeemLink.innerHTML,
                    pageUrl: window.location.href,
                });
            } catch (error) {
                if (error instanceof ReferenceError) {
                }
            } finally {
                UrlHelper.goTo(redeemLink.href);
            }
        });
    }

    setupStudentTracking() {
        let studentLink = document.querySelectorAll('.faq li:nth-child(2) a')[0],
            href = studentLink?.href;

        if (!studentLink) return;

        studentLink.addEventListener('click', function (e) {
            e.preventDefault();

            try {
                om.trackAction('trackGetOffer', {
                    pageTitle: document.title,
                    ctaText: studentLink.innerHTML,
                    pageUrl: window.location.href,
                });
            } catch (error) {
                if (error instanceof ReferenceError) {
                }
            } finally {
                UrlHelper.goTo(href);
            }
        });
    }

    setupProviderLinkTracking() {
        let providerLink = this.getFaqLink('partner');

        if (!providerLink) {
            return;
        }

        providerLink.addEventListener('click', function (e) {
            e.preventDefault();

            try {
                om.trackAction('trackUpsellLinkTvProviderSelect', {
                    pageTitle: document.title,
                    ctaText: providerLink.innerText,
                    pageUrl: window.location.href,
                });
            } catch (error) {
            } finally {
                UrlHelper.goTo(providerLink.href);
            }
        });
    }

    needToTrackElement(idx) {
        return this.trackingStatus[idx];
    }

    handleScroll() {
        this.elements.forEach((el, idx) => {
            if (this.needToTrackElement(idx)) {
                this._tryTracking(el, idx);
            }
        });
    }

    handleMenuClick(idx) {
        let el = this.clickElements[idx];
        this._tryTracking(el, idx, 'trackUpsellRowHeaderClick');
    }

    handleCTAButtonClick(idx) {
        let el = this.upsellButtons[idx];
        let obj = {
            clickLabel: el.text.trim(),
        };

        if (Domain.isInternational()) {
            if (idx === 1) {
                trackAction('trackLogin', obj);
            } else if (idx === 2) {
                obj.pageType = 'svod_upsell';
                trackAction('trackSignInTvProvider', obj);
            }
        }
    }

    // Trigger trackLogin action when click on signin from upsell page
    setupUpsellSignInTracking() {
        let ul = document.querySelector('#user-profiles-menu-trigger');
        let signinLink = ul.querySelector('#js-li-sign-in a');

        if (!signinLink) return;

        signinLink.addEventListener('click', function (e) {
            e.preventDefault();
            trackAction('trackLogin', { clickLabel: signinLink.text.trim() });
            window.location = signinLink.href;
        });
    }

    setupUpsellCtaTracking() {
        let ctaText = document.querySelectorAll('.js-cta-tracking');

        ctaText.forEach((el, idx) => {
            el.addEventListener('click', function (e) {
                let trackingObj = {
                    ctaText: el.querySelector('.button__text').innerHTML.trim(),
                    rowHeaderPosition: getRowHeaderPosition(el),
                    rowHeaderNumberTotal: getRowHeaderNumberTotal(),
                    rowHeaderTitle: getRowHeaderTitle(el),
                };

                let cbscidmt = new URLSearchParams(window.location.search).get('cbscidmt');

                if (cbscidmt && el.href) {
                    trackingObj.targetURL = el.href;
                }

                om.trackAction('trackUpsellRowHeaderCTA', trackingObj);
            });
        });
    }

    setupMobileTracking() {
        if (this.isMobileCtaPresent.length > 0) {
            let ctaMobile = this.isMobileCtaPresent[0];
            ctaMobile.addEventListener('click', (e) => {
                this.doCurrentSectionTracking(e.target);
            });
        }
    }

    doCurrentSectionTracking(btn) {
        let ctaBottom = btn.getBoundingClientRect().bottom;

        this.elements.forEach((el, idx) => {
            let sectionPositionBottom = el.getBoundingClientRect().bottom;

            if (
                sectionPositionBottom >= 0 &&
                sectionPositionBottom < ctaBottom &&
                sectionPositionBottom <= this.constructor.getMiddleOfScreen()
            ) {
                let obj = {
                    // set to row header title when it comes into full view
                    rowHeaderTitle: getRowHeaderTitle(el),

                    // set to row header title number on y axis (Hero can be set to "0")
                    rowHeaderPosition: getRowHeaderPosition(el),

                    // set to static value per total amount of row header sections on page
                    rowHeaderNumberTotal: getRowHeaderNumberTotal(),

                    //cta Text
                    ctaText: btn.innerText.trim(),
                };

                CBS.Promises.Tracking.onLoad(function () {
                    om.trackAction('trackUpsellRowHeaderCTA', obj);
                });
            }
        });
    }

    _tryTracking(el, idx) {
        let bottom = el.getBoundingClientRect().bottom;

        if (bottom >= 0 && bottom <= this.constructor.getMiddleOfScreen()) {
            try {
                const rowHeaderPosition = getRowHeaderPosition(el);
                if (rowHeaderPosition >= 0) {
                    let ctaText =
                        this.isMobileCtaPresent > 0
                            ? this.isMobileCtaPresent[0].innerText.trim()
                            : (el
                                  .closest('.aa-section')
                                  .querySelector('.js-cta-tracking .button__text')
                                  ?.innerHTML.trim() ?? '');
                    let obj = {
                        // set to row header title when it comes into full view
                        rowHeaderTitle: getRowHeaderTitle(el),

                        // set to row header title number on y axis (Hero can be set to "0")
                        rowHeaderPosition: rowHeaderPosition,

                        // set to static value per total amount of row header sections on page
                        rowHeaderNumberTotal: this.rowHeaderNumberTotal,
                        //cta Text
                        ctaText: ctaText,
                    };

                    let trackParamScroll = document.body.classList.contains('pplus')
                        ? 'trackpplusRowHeader'
                        : 'trackUpsellRowHeader';

                    setTimeout(() => om.trackAction(trackParamScroll, obj), 500);

                    // do not track this heading again
                    this.trackingStatus[idx] = false;
                }
            } catch (e) {
                // om not available yet
                //console.error(e.message);
            }
        }
    }

    showtimeBundleCtaTracking() {
        if (
            this.getPartnerBundleBannerClass.length === 0 &&
            this.getShowtimeBundleBannerClass.length > 0
        ) {
            let showtimeBundleLink = this.getShowtimeBundleBannerClass[0].querySelector('a'),
                href = showtimeBundleLink.href;

            showtimeBundleLink.addEventListener('click', function (e) {
                e.preventDefault();

                try {
                    om.trackAction('trackUpsellGetShowtimeBundle', {
                        ctaText: showtimeBundleLink.querySelector('.button__text').innerHTML.trim(),
                        partnerBundle: 'showtime',
                    });
                } catch (error) {
                    if (error instanceof ReferenceError) {
                    }
                } finally {
                    window.location = href;
                }
            });
        }
    }

    partnerBundleCtaTracking() {
        if (this.getPartnerBundleBannerClass.length > 0) {
            let partnerBundleLink = this.getPartnerBundleBannerClass[0].querySelector('a'),
                href = partnerBundleLink.href;

            partnerBundleLink.addEventListener('click', function (e) {
                e.preventDefault();

                try {
                    om.trackAction('trackUpsellGetPartnerBundle', {
                        ctaText: partnerBundleLink.querySelector('.button__text').innerHTML.trim(),
                        partnerBundle: Product.ADDON_PARTNER_BUNDLE,
                    });
                } catch (error) {
                    if (error instanceof ReferenceError) {
                    }
                } finally {
                    window.location = href;
                }
            });
        }
    }

    // START CBSCOMSUB-6356: Free Content Hub
    freeContentHubCTATracking() {
        const cta = document.querySelector('.free-content-hub-cta-wrapper a.tertiary');

        if (!cta) {
            return;
        }

        cta.addEventListener('click', () => {
            try {
                om.trackAction('trackFreeContentHubCta', {
                    ctaText: cta.querySelector('.button__text').innerHTML.trim(),
                });
            } catch (e) {}
        });
    }

    // END CBSCOMSUB-6356: Free Content Hub

    // START TEST PLGRWEB-366 : Test Showtime Upsell Screen Subcopy Link to Learn More
    showtimeUpsellScreenSubcopyTracking() {
        const showtimeUpsellScreenSubcopy = document.getElementById(
            'showtime-upsell-screen-subcopy',
        );

        if (!showtimeUpsellScreenSubcopy) return;

        const href = showtimeUpsellScreenSubcopy.getAttribute('href');
        const text = showtimeUpsellScreenSubcopy.innerText;

        showtimeUpsellScreenSubcopy.addEventListener('click', function (e) {
            e.preventDefault();

            try {
                om.trackAction('trackUpsellLearnMoreCTA', {
                    ctaText: text,
                    optimizelyExp: utag.data.optimizelyExp,
                });
            } catch (error) {
            } finally {
                window.location = href;
            }
        });
    }
    // END TEST PLGRWEB-366 : Test Showtime Upsell Screen Subcopy Link to Learn More

    getFaqLink(linkRegex) {
        let faqLinks = document.querySelectorAll('.faq > li > div > a');
        let faqLink = '';
        let regex = new RegExp(linkRegex);

        faqLinks.forEach((link) => {
            if (link.href.match(regex)) {
                faqLink = link;
            }
        });

        return faqLink;
    }

    static getMiddleOfScreen() {
        return Math.round(window.innerHeight / 2);
    }

    static onOmLoad(callback) {
        if (typeof om === 'undefined') {
            setTimeout(() => {
                this.onOmLoad(callback);
            }, 200);
        } else {
            callback();
        }
    }
}

export let getRowHeaderNumberTotal = () => {
    return document.querySelectorAll('.aa-section').length;
};

export let getRowHeaderPosition = (el) => {
    return el.hasAttribute('data-row-position')
        ? el.getAttribute('data-row-position').toString()
        : getHeaderElement(el).getAttribute('data-row-position').toString();
};

export let getRowHeaderTitle = (el) => {
    return el.hasAttribute('data-row-position')
        ? el.textContent.trim()
        : getHeaderElement(el).textContent.trim();
};

const getHeaderElement = (el) => {
    return el.closest('.aa-section').querySelector('h1, h3');
};
